
export const state = {
    errors: {},
}


export const actions = {
    setErrors(errors) {
        this.errors = errors
    },
    clearErrors() {
        this.errors = {}
    },
    deleteError(key) {
        delete this.errors[key]
    }
}

